import React, { useState, useRef, useId } from 'react'
import clsx from 'clsx'

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'size' | 'onChange'
  > {
  label: string
  value?: string
  onChange?: (value: string) => void
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  name?: string
  mask?: (unmaskedInput: string) => string
  textAlign?: 'left' | 'center' | 'right'
  autoComplete?: React.HTMLInputAutoCompleteAttribute
  hideArrows?: boolean
}

export function maskVerificationCode(value: string): string {
  const numericValue = value.replace(/\D/g, '')
  const truncatedValue = numericValue.slice(0, 6)

  return `${truncatedValue.slice(0, 3)} ${truncatedValue.slice(3)}`.trimEnd()
}

const TextInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  size = 'medium',
  disabled = false,
  name,
  mask = (unmaskedInput) => unmaskedInput,
  textAlign,
  autoComplete = 'on',
  hideArrows = false,
  ...rest
}) => {
  const id = `${name}-${useId()}`
  const [searchValue, setSearchValue] = useState(value || '')
  const inputRef = useRef<HTMLInputElement>(null)

  const updateSearchValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    setSearchValue(mask(value))
    if (onChange) {
      onChange(value)
    }
  }

  const focusSearchInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className="relative w-full">
      <input
        id={id}
        ref={inputRef}
        value={searchValue}
        onChange={updateSearchValue}
        className={clsx(
          'text-neutral-60-primary-text bg-neutral-5-background border-b-1 rounded-t-md border-neutral-30-inactive focus:outline-hidden peer align-bottom w-full',
          {
            'pt-5 pb-2 px-3.5 title-md': size === 'small',
            'pt-6 pb-2 px-3.5 title-lg': size === 'medium',
            'pt-8 pb-2 px-3.5 title-xl': size === 'large',
            'text-left': textAlign === 'left',
            'text-center': textAlign === 'center',
            'text-right': textAlign === 'right',
            'hide-arrows': hideArrows,
          },
        )}
        disabled={disabled}
        placeholder=""
        name={name}
        autoComplete={autoComplete}
        {...rest}
      />{' '}
      <label
        htmlFor={id}
        className={clsx(
          'absolute text-neutral-50-secondary-text bg-neutral-5-background transition-all duration-300 transform -translate-y-4 px-4 top-4.5 left-0 z-10 origin-[0] peer-placeholder-shown:scale-100 peer-focus:-translate-y-4',
          {
            'peer-placeholder-shown:-translate-y-0.5 peer-focus:scale-70 scale-70 body-md peer-placeholder-shown:px-4':
              size === 'small',
            'peer-placeholder-shown:translate-y-0 peer-focus:scale-75 scale-75 body-lg peer-placeholder-shown:px-4':
              size === 'medium',
            'peer-placeholder-shown:translate-y-1 peer-focus:scale-80 scale-80 body-xl peer-placeholder-shown:px-4':
              size === 'large',
            'cursor-text': !disabled,
          },
        )}
        onClick={focusSearchInput}
      >
        {label}
      </label>
    </div>
  )
}

export default TextInput
